import * as React from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout"
import {Box, Container, Text} from "@chakra-ui/react";

const NotFoundPage = ({ data, location }) => {
  //const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={location} title={"404: Not Found"}>
        <Container maxW="container.xl" sx={{
                margin: `10px auto`,
                pl:['2px','10px','10px'],
                pr:['2px','10px','10px'],
            textAlign:'center'
            }}>
            <h1>404: Not Found</h1>
      <p>Erreur sur la page :( </p>
            <Box sx={{fontWeight:'bold'}}><Link to={"/"}><Text sx={{textDecoration:'underline'}}>-> Revenir sur la page principale</Text> </Link></Box>
        </Container>

    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
